// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-circle-of-life-award-application-js": () => import("./../../../src/pages/circle-of-life-award/application.js" /* webpackChunkName: "component---src-pages-circle-of-life-award-application-js" */),
  "component---src-pages-circle-of-life-award-js": () => import("./../../../src/pages/circle-of-life-award.js" /* webpackChunkName: "component---src-pages-circle-of-life-award-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactive-about-the-production-js": () => import("./../../../src/pages/interactive/about-the-production.js" /* webpackChunkName: "component---src-pages-interactive-about-the-production-js" */),
  "component---src-pages-interactive-fascinating-facts-js": () => import("./../../../src/pages/interactive/fascinating-facts.js" /* webpackChunkName: "component---src-pages-interactive-fascinating-facts-js" */),
  "component---src-pages-interactive-meet-the-characters-js": () => import("./../../../src/pages/interactive/meet-the-characters.js" /* webpackChunkName: "component---src-pages-interactive-meet-the-characters-js" */),
  "component---src-pages-interactive-the-story-js": () => import("./../../../src/pages/interactive/the-story.js" /* webpackChunkName: "component---src-pages-interactive-the-story-js" */),
  "component---src-pages-music-play-day-js": () => import("./../../../src/pages/music-play-day.js" /* webpackChunkName: "component---src-pages-music-play-day-js" */),
  "component---src-pages-our-first-show-js": () => import("./../../../src/pages/our-first-show.js" /* webpackChunkName: "component---src-pages-our-first-show-js" */),
  "component---src-pages-sunrise-scheme-index-js": () => import("./../../../src/pages/sunrise-scheme/index.js" /* webpackChunkName: "component---src-pages-sunrise-scheme-index-js" */),
  "component---src-pages-teacher-library-js": () => import("./../../../src/pages/teacher-library.js" /* webpackChunkName: "component---src-pages-teacher-library-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-video-hub-js": () => import("./../../../src/pages/video-hub.js" /* webpackChunkName: "component---src-pages-video-hub-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

